import React from 'react';
import { FaAward } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import ME from '../../assets/me.jpg';
import './intro.css';

const Intro = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Industry Experience</h5>
              <small>2 years</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Instructional/Mentoring Experience</h5>
              <small>1 year</small>
            </article>
          </div>
          <p>
            My goal has always been to become a software engineer. Although I
            started as an Application Developer after university, I decided to
            gain more depth of knowledge. The desire to learn culminated into my
            attendance in a Software Engineering Immersive Bootcamp called
            Fullstack Academy. While I still strive to be a successful engineer,
            I am currently an instructor with a goal to inspire the next wave of
            software engineers.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
